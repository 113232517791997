@import 'src/styles/grid.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/spaces.scss';

.container {
  display: inline-block;
  padding: grid_units(0.5) grid_units(1);
  border-radius: grid_units(1);
  background-color: $burgundy-500;
  white-space: nowrap;
  width: auto;
}

.content {
  color: white;
  font-weight: bold;
}
