/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';

.housingContainer {
  padding-top: $spaces-6;
  padding-bottom: $spaces-6;
  background-color: $grey-100;
  @include isDevice(tablet) {
    padding-top: $spaces-10;
    padding-bottom: $spaces-10;
  }
  @include isDevice(desktop) {
    padding-top: $spaces-11;
    padding-bottom: $spaces-11;
  }
  .title {
    letter-spacing: normal;
    text-align: center;
    @include isDevice(desktop) {
      max-width: 750px;
      margin: 0 auto;
    }
  }
  .subtitle {
    margin-top: 16px;
    font-size: 20px;
    letter-spacing: normal;
    text-align: center;
    @include isDevice(desktop) {
      margin-top: 24px;
      margin-bottom: 80px;
    }
  }
  .cardsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: $spaces-3;
    @include isDevice(tablet) {
      flex-direction: column;
      gap: $spaces-5;
    }
    @include isDevice(desktop) {
      flex-direction: row;
      align-items: inherit;
      gap: $spaces-5;
    }
    .cardsRow {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: $spaces-3;
      @include isDevice(tablet) {
        flex-direction: row;
      }
      @include isDevice(desktop) {
        flex-direction: row;
        gap: $spaces-5;
      }
      &:last-of-type {
        & > :last-of-type {
          display: none;
          @include isDevice(tablet) {
            display: block;
          }
          @include isDevice(desktop) {
            display: none;
          }
        }
      }
    }
  }
  .housingButtonWrapper {
    margin-top: $spaces-5;
    text-align: center;
    @include isDevice(tablet) {
      margin-top: $spaces-10;
    }
    @include isDevice(desktop) {
      margin-top: $spaces-10;
    }
    .housingButton {
      width: 100%;
      @include isDevice(tablet) {
        width: auto;
      }
      @include isDevice(desktop) {
        width: auto;
      }
    }
  }
}
