/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';

.footerClass {
  padding-bottom: 60px;
  @include isDevice(desktop) {
    padding-bottom: 0;
  }
}
