/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/spaces.scss';

.whySmartHousingContainer {
  padding-top: $spaces-6;
  padding-bottom: $spaces-5;
  background-color: $white;

  @include isDevice(tablet) {
    padding-top: $spaces-10;
    padding-bottom: $spaces-10;
  }

  @include isDevice(desktop) {
    padding-top: $spaces-11;
    padding-bottom: $spaces-10;
  }

  .subtitle {
    margin-top: $spaces-2;

    @include isDevice(desktop) {
      margin-top: $spaces-3;
    }
  }
  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $spaces-5;
    margin-bottom: $spaces-2;
    gap: $spaces-3;

    @include isDevice(tablet) {
      margin-top: $spaces-5;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @include isDevice(desktop) {
      margin-top: $spaces-10;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .image {
      height: auto;
      max-width: 260px;
      @include isDevice(tablet) {
        width: 100%;
        max-width: 394px;
      }
      @include isDevice(desktop) {
        max-width: 408px;
      }
    }

    .info {
      max-width: 572px;
      @include isDevice(tablet) {
        max-width: 352px;
      }
      @include isDevice(desktop) {
        max-width: 572px;
      }

      .infoSubtitle {
        margin-top: $spaces-2;
        @include isDevice(tablet) {
          margin-top: $spaces-3;
        }
        @include isDevice(desktop) {
          margin-top: $spaces-3;
        }
      }

      .bulletPointsContainer {
        margin-top: $spaces-2;
        margin-bottom: $spaces-2;
        padding: $spaces-2;
        background-color: $red-100;
        display: flex;
        flex-direction: column;
        gap: $spaces-3;
        @include isDevice(desktop) {
          margin-top: $spaces-6;
          margin-bottom: $spaces-6;
          padding: $spaces-3;
        }

        .bullet {
          display: flex;
          gap: $spaces-1;

          @include isDevice(desktop) {
            gap: $spaces-2;
            align-items: center;
          }
        }
      }

      .infoButtonContainer {
        margin-top: $spaces-3;
        .infoButton {
          width: 100%;
          font-family: Apercu, sans-serif;
          text-rendering: geometricprecision;
          font-size: 14px;
          font-weight: 700;

          @include isDevice(desktop) {
            width: 370px;
          }
        }
        @include isDevice(tablet) {
          display: none;
        }
        @include isDevice(desktop) {
          margin-top: $spaces-6;
        }
      }
    }
  }
  .infoButtonTabletContainer {
    margin-top: $spaces-7;
    display: none;
    justify-content: center;
    @include isDevice(tablet) {
      display: flex;
    }
    .infoButtonTablet {
      font-family: Apercu, sans-serif;
      text-rendering: geometricprecision;
      font-size: 14px;
      font-weight: 700;
      padding: 0 125px;
      align-self: center;
    }
  }
}
