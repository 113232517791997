/* stylelint-disable */

@import 'src/styles/utils';
@import 'src/styles/palette';
@import 'src/styles/spaces.scss';

.card {
  position: relative;
  width: 330px;
  transform: scale(1, 1);
  transition: transition_all(300);
  border-radius: $spaces-2;
  background: $white;
  border-width: 1px;
  border-style: solid;
  border-color: $grey-100; // TODO: customize with a className
}

.cardClickable {
  width: 100%;
  padding: 0;
  border: none;
  background-color: $white;
  text-align: left;
  text-decoration: none;
  cursor: pointer;

  &:hover .card {
    border: 1px solid #c0c7ce;
  }
  &:active .card {
    border: 1px solid #96a2ad;
  }
}

.banner {
  position: absolute;
  z-index: 10;
  top: grid_units(2);
  right: grid_units(2);
}
.sliderContainer {
  position: relative;
  .sliderContainerPaginator {
    display: flex;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 2px;
    left: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 11px 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%);
    color: white;
    gap: 6px;
  }
}

.mainImageContainer {
  width: 100%;
  height: grid_units(27);
  border-top-left-radius: $spaces-2;
  border-top-right-radius: $spaces-2;
}
.mainImage {
  height: grid_units(27);
  border-top-left-radius: $spaces-2;
  border-top-right-radius: $spaces-2;
  object-fit: cover;
}

.sliderButton {
  display: inline-flex;
  position: absolute;
  z-index: 1;
  top: 50%;
  align-items: center;
  justify-content: center;
  padding: grid_units(1);
  transform: translateY(-50%);
  border-radius: 100%;
  background-color: #fff;
  &:hover {
    .sliderButtonIcon {
      opacity: 0.6;
    }
  }
}

.sliderButtonNext {
  right: grid_units(3);
}

.sliderButtonPrev {
  left: grid_units(3);
}
.sliderButtonNext,
.sliderButtonPrev {
  display: inline-flex !important;
}

.cardDetails {
  padding: grid_units(2);
}

.housingCardContent {
  background-color: $white;
}

.zone {
  color: $blue-300;
  font-weight: 400;
}

.title {
  display: -webkit-box;
  margin-top: $spaces-1;
  margin-bottom: $spaces-1;
  font-size: 20px;
  min-height: 60px;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pricePerMonth {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  .month {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.downPayment {
  color: $blue-300;
  font-weight: bold;
}

.row {
  display: flex;
  margin-top: $spaces-2;
  margin-bottom: $spaces-1;
}

.rowItem {
  display: flex;
  align-items: center;
  margin-right: $spaces-3;
}

.rowItemPrice {
  display: flex;
  align-items: center;
  margin-right: $spaces-1;
}

.separator {
  border: 1px $grey-100 solid;
}

.icon {
  margin-right: $spaces-1;
}
