@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
.article {
  display: block;
  box-sizing: border-box;
  max-width: 330px;
  height: 530px;
  margin: 0 12px;
  padding: 24px;
  border: 1px solid transparent;
  border-radius: 16px;
  background: $grey-100;
  text-decoration: none;

  @include isDevice(mobile) {
    max-width: 100%;
  }

  @include isDevice(desktop) {
    height: 520px;
  }

  &:hover {
    border: 1px solid $grey-200;
  }

  &:active {
    border: 1px solid $grey-300;
  }

  .articleLogo {
    width: auto;
    height: 32px;
  }

  .articleTitle {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 28px;
    letter-spacing: normal;
    line-height: 34px;
  }

  .articleDescription {
    display: -webkit-box;
    overflow: hidden;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 30px;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
}
