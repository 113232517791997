@import 'src/styles/grid.scss';
@import 'src/styles/spaces.scss';
@import 'src/styles/palette.scss';

.root {
  background-color: $grey-100;

  .container {
    @include container;
    display: flex;
    flex-direction: column;
    padding-block: $spaces-6;
    gap: $spaces-3;

    @include isDevice(tablet) {
      padding-block: $spaces-10;
      gap: $spaces-7;
    }

    @include isDevice(desktop) {
      padding-block: $spaces-10;
      gap: $spaces-10;
    }

    .dataInfoContainer {
      display: flex;
      flex-direction: column;
      gap: $spaces-3;

      @include isDevice(tablet) {
        gap: $spaces-7;
      }

      @include isDevice(desktop) {
        flex-direction: row;
        gap: $spaces-3;
      }

      .dataInfoItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $spaces-3;

        .title {
          font-size: $spaces-10;
          line-height: $spaces-7;
        }

        @include isDevice(tablet) {
          gap: $spaces-5;
        }

        @include isDevice(desktop) {
          gap: $spaces-5;
        }
      }
    }
  }
}
