/* stylelint-disable */

@import 'src/styles/utils.scss';
@import 'src/styles/palette.scss';
@import 'src/styles/grid.scss';
@import 'src/styles/spaces.scss';

.articlesContainer {
  padding-top: 48px;
  padding-right: 24px;
  padding-bottom: 48px;
  padding-left: 24px;
  background-color: $white;

  @include isDevice(tablet) {
    padding-top: $spaces-10;
    padding-bottom: $spaces-10;
  }

  @include isDevice(desktop) {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .title {
    letter-spacing: normal;
    text-align: center;
  }

  .subtitle {
    margin-top: 16px;
    font-size: 20px;
    letter-spacing: normal;
    text-align: center;

    @include isDevice(desktop) {
      margin-top: 24px;
    }
  }

  .sliderContainer {
    max-width: 1160px;
    margin: 0 auto;
    margin-top: 40px;

    @include isDevice(desktop) {
      margin-top: 80px;
    }
  }
}
